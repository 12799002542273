@import "src/assets/styles/colors";

.Button, .ButtonIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  font-family: 'Poppins', sans-serif;
}

.Button {
  height: fit-content;
  padding: 8px 16px;
  gap: 8px;
  font-size: 16px;

  &.__withIcon {
    padding: 6px 14px 6px 8px;
  }

  &.__small {
    font-size: 14px;
    height: 32px;
  }

  &:global(.ant-btn) {
    font-stretch: normal;
    font-style: normal;
    border-radius: 4px;

    &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.25);
      background: $color-white-0;
      border-color: #d9d9d9;
      text-shadow: none;
      box-shadow: none;
    }
  }

  span {
    &:first-letter {
      text-transform: uppercase;
    }
    line-height: 24px;
  }

  &.secondary {
    padding: 6px 14px;
    border: solid 2px $color-blue-7;
    background-color: $color-white-0;
    color: $color-blue-7;

    svg {
      margin-top: -2px;
    }

    path {
      stroke: $color-blue-7;
    }

    &:hover, &:focus, &:active {
      border: solid 2px $color-blue-8;
      background-color: $color-white-0;
      color: $color-blue-8;

      path {
        stroke: $color-blue-8;
      }
    }

    &:focus, &:active {
      border: solid 2px $color-blue-6;
      background-color: $color-white-0;
      color: $color-blue-6;

      path {
        stroke: $color-blue-6;
      }
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
      border: solid 2px $color-grey-13;
      background-color: $color-white-0;
      color: $color-grey-13;

      path {
        stroke: $color-grey-13;
      }
    }
  }

  &.warning {
    border: none;
    background-color: $color-red-2;
    color: $color-white-0;

    path {
      stroke: $color-white-0;
    }

    &:hover {
      background-color: $color-red-1;
    }

    &:focus, &:active {
      background-color: $color-red-5;
    }

    &:hover, &:focus, &:active {
      border: none;
      color: $color-white-0;

      path {
        stroke: $color-white-0;
      }
    }

    &.__disabled {
      background-color: $color-black-70;
      color: $color-white-0;

      &:hover {
        cursor: not-allowed;
      }
    }

  }

  &.tertiary_warning {
    border: none;
    background-color: $color-white-0;
    color: $color-red-2;

    path {
      stroke: $color-red-2;
    }

    &:hover, &:focus, &:active {
      border: none;
      background-color: $color-white-0;
      color: $color-red-1;

      path {
        stroke: $color-red-1;
      }
    }
  }

  &.default {
    background-color: $color-blue-7;
    color: $color-white-0;
    border: none;

    &:hover {
      background-color: $color-blue-8;
      color: $color-white-0;
      border: none;
    }

    &:focus, &:active {
      background-color: $color-blue-6;
      color: $color-white-0;
      border: none;
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active  {
      background-color: $color-grey-13;
      color: $color-white-0;
      border: none;
    }
  }

  &.tertiary {
    border: none;
    background-color: $color-white-0;
    color: $color-blue-7;

    &:hover {
      border: none;
      background-color: $color-white-0;
      color: $color-blue-8;

      path {
        stroke: $color-blue-8;
      }
    }

    &:focus, &:active {
      border: none;
      background-color: $color-white-0;
      color: $color-blue-6;

      path {
        stroke: $color-blue-6;
      }
    }

    &.__withIcon {
      padding: 8px 14px 8px 8px;
    }
  }

  &.square, &.rectangle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 11px;
    min-width: 136px;
    max-width: 136px;
    min-height: 136px;
    max-height: 136px;
    border: solid 2px $color-blue-7;
    background-color: $color-white-0;
    color: $color-blue-7;
    circle {
      fill: $color-blue-7;
    }
    & * {
      stroke: $color-blue-7;
    }

    &:hover, &:focus, &:active {
      border: solid 2px $color-blue-8;
      background-color: $color-white-0;
      color: $color-blue-8;
      circle {
        fill: $color-blue-8;
      }
      & * {
        stroke: $color-blue-8;
      }
    }

    &:focus, &:active {
      border: solid 2px $color-blue-6;
      background-color: $color-white-0;
      color: $color-blue-6;
      circle {
        fill: $color-blue-6;
      }
      & * {
        stroke: $color-blue-6;
      }
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
      border: solid 2px $color-grey-13;
      background-color: $color-white-0;
      color: $color-grey-13;
      circle {
        fill: $color-grey-13;
      }
      & * {
         stroke: $color-grey-13;
       }
    }

    svg {
      margin-bottom: 10px;
    }
  }

  &.rectangle {
    min-width: 104px;
    max-width: 136px;
    border-radius: 6px;

    :global(.ant-btn-loading-icon) {
      position: absolute;
      top: 40%;
    }
  }
}

.ButtonIcon {
  border: none;
  background-color: $color-white-0;
  width: 40px;
  height: 40px;
  color: $color-blue-7;

  path {
    stroke: $color-blue-7;
    fill: transparent;
  }

  &.__blackIcon {
    path {
      stroke: $color-black-1;
    }

    &:active a, &:active path, &:focus path, &:hover path {
      stroke: $color-blue-6;
      color: $color-blue-6;
    }

    &:hover, &:focus, &:active {
      background: none;
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
      background: none;

      path {
        stroke: $color-grey-13;
      }
    }
  }

  &:hover, &:focus, &:active {
    :global(.ant-pagination-item-ellipsis) {
      opacity: 1;
    }
  }

  &:hover a, &:hover path, &:focus a, &:focus path {
    color: $color-blue-8;
    stroke: $color-blue-8;
  }

  &:active a, &:active path {
    stroke: $color-blue-6 !important;
    color: $color-blue-6 !important;
  }

  :global(.ant-pagination-item-container) {
    display: flex;
  }

  :global(.ant-pagination-item-ellipsis) {
    font-size: 14px;
  }

  &:active a, &:active path {
    stroke: $color-blue-6 !important;
    color: $color-blue-6 !important;
  }

  &:target, &:target path, &:target a {
    stroke: $color-white-0;
    color: $color-white-0 !important;
    background-color: $color-blue-7 !important;
  }

  &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
    path {
      stroke: $color-grey-13 !important;
    }
  }

  &.__small {
    width: 32px;
    height: 32px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

:global(.ant-btn-loading-icon) {
  height: 24px;
}

[ant-click-animating-without-extra-node=true]:after {
  box-shadow: none !important;
}
