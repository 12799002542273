@import 'src/assets/styles/colors';

.MainLayout {
  display: flex;
  flex: auto;
  flex-direction: column;
}

.LayoutSpin {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.Body {
  display: flex;
  height: 100vh;
  overflow: hidden;
  gap: 16px;
}
