@import "colors";

@mixin font-face-mixin ($name, $src, $weight: null, $style: null)  {
  @font-face {
    font-family: $name;
    src: $src format('woff2');
    font-weight: $weight;
    font-style: $style;
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+0370-03FF;
  }
}

@mixin colorizeDropdown($background, $color) {
  background-color: $background;
  color: $color;
  &:global(.ant-dropdown-open) {
    background-color: darken($background,7%);
  }
  path {
    stroke: $color;
  }
}

@mixin classifications {
  display: flex;
  align-items: center;
  height: 19px;
  padding: 1.5px 8px;
  margin: 0;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  overflow: hidden;
  background-color: $color-blue-10;
  color: $color-black-1;
  &:global(.ant-dropdown-open) {
    background-color: $color-blue-10;
  }

  :global(.ant-select-selection-item-remove) {
    height: 16px;
  }
  path {
    fill: $color-black-1;
  }

  &_Text {
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@mixin resultsActiveBlock($left, $right){
  content: '';
  position: absolute;
  top: -1px;
  left: $left;
  right: $right;
  width: 40px;
  height: calc(100% + 2px);
  background-color: $color-white-7;
}
