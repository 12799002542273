@import '~antd/dist/antd.css';
@import 'assets/styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include font-face-mixin('Poppins', url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap'), 500, normal);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

*,
*:before,
*:after {
  /* Predictable elements sizing */
  box-sizing: border-box;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}
