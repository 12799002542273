@import 'src/assets/styles/colors';

.Modal {
  padding: 0;

  &.small {
    width: 400px !important;
  }

  &.medium {
    width: 480px !important;
  }

  &.big {
    width: 800px !important;
  }

  &.free {
    width: fit-content !important;
  }

  &.loading {
    :global(.ant-modal-content) {
      padding: 48px 130.5px;
    }
  }

  :global(.ant-modal-body) {
    padding: 0;
  }

  :global(.ant-modal-content) {
    padding: 48px 40px;
    border-radius: 8px;
    box-shadow: 0 2px 8px $color-grey-15;
  }

  :global(.ant-modal-close) {
    top: 16px;
    right: 16px;

    svg {
      path {
        stroke: $color-grey-13;
      }

      &:hover {
        path {
          stroke: $color-black-0;
        }
      }
    }
  }

  :global(.ant-modal-close, .ant-modal-close-x) {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}

.Title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: $color-black-1;
}

.TitleCaption, .SecondaryText {
  font-size: 16px;
  line-height: 24px;
}

.TitleCaption {
  margin-top: 8px;
}

.SecondaryText {
  margin-top: 24px;
}

.Body, .ActionsButtons {
  margin-top: 40px;
}

.Body {
  &.loading {
    margin-top: 16px;
  }
}

.ActionsButtons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
