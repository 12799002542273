@import "src/assets/styles/colors";

.Alerts {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 2;
  transform: translateZ(1px);
}

.Alert {
  display: flex;
  z-index: 1100;
  width: fit-content;
  margin-left: auto;
  padding: 9.5px 16px;
  align-items: center;
  gap: 18px;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: 0 2px 8px $color-grey-15;
  margin-bottom: 1.25rem;

  &.success {
    background-color: $color-green-0;

    .AlertCross path {
      stroke: $color-green-1;
    }

    .AlertPrefixIcon path {
      fill: $color-green-1;
    }
  }

  &.warning {
    background-color: $color-yellow-0;

    .AlertCross path {
      stroke: $color-yellow-1;
    }
  }

  &.error {
    background-color: $color-red-0;

    .AlertCross path {
      stroke: $color-red-2;
    }
  }

  .AlertPrefixIcon {
    display: flex;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .AlertMessage {
    max-width: 70vw;
    word-break: break-all;
  }

  .AlertCross {
    display: block;
    align-items: center;
    height: 24px;
    width: 24px;
    padding: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
}
