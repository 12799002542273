@import "src/assets/styles/colors";

.App {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: stretch;
  justify-content: center;

  .Body {
    display: flex;
    flex: auto;
    overflow: auto;
    background-color: $color-grey-8;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 12px;
    }
  
    &::-webkit-scrollbar-track {
      background: $color-white-0;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: $color-white-5;  
      border: 4px solid $color-white-0;
      border-radius: 8px; 
    }
  }
}
