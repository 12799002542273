$color-white-0: #ffffff;
$color-white-1: #f2f6ff;
$color-white-2: #e1e5eb;
$color-white-3: #f0f0f0;
$color-white-4: #f0f5ff;
$color-white-5: #d9d9d9;
$color-white-6: #fafafa;
$color-white-7: #f0f4fe;

$color-black-0: #000000;
$color-black-1: #1A1A1A;
$color-black-2: #151b24;
$color-black-3: #354356;
$color-black-4: #333333;
$color-black-5: #4D4D4D;
$color-black-30: #4d4d4d;
$color-black-70: #b3b3b3;
$color-black-80: #cccccc;
$color-black-90: #e6e6e6;
$color-black-96: #F5F5F5;
$color-black-97: #C4C4C4;

$color-grey-0: rgba(174,180,187, 0.6);
$color-grey-1: rgba(199, 207, 219, 0.5);
$color-grey-2: rgba(17, 2, 64, 0.15);
$color-grey-3: rgba(0, 0, 0, 0.2);
$color-grey-4: rgba(53, 67, 86, 0.1);
$color-grey-5: rgba(53, 67, 86, 0.6);
$color-grey-6: rgba(173, 198, 255, 0.2);
$color-grey-7: rgba(174, 180, 187, 1);
$color-grey-8: rgba(245, 245, 245, 1);
$color-grey-9: rgba(230, 230, 230, 1);
$color-grey-10: rgba(77, 77, 77, 1);
$color-grey-11: rgba(204, 204, 204, 1);
$color-grey-12: rgba(179, 179, 179, 1);
$color-grey-13: rgba(178, 178, 178, 1);
$color-grey-14: rgba(229, 229, 229, 1);
$color-grey-15: rgba(26, 26, 26, 0.15);
$color-grey-16: rgba(242,242,242, 1);

$color-green-0: rgba(244, 253, 232, 1);
$color-green-1: rgba(103, 174, 14, 1);
$color-green-2: rgba(103, 174, 14, 0.7);
$color-green-3: #E7F2D8;
$color-green-4: #465039;
$color-green-5: #E1F8F6;
$color-green-6: #145E59;
$color-green-7: #67AE0E;

$color-yellow-0: rgba(253, 248, 232, 1);
$color-yellow-1: rgba(249, 234, 184, 1);
$color-yellow-2: rgba(255, 199, 17, 0.2);
$color-yellow-3: #786113;
$color-yellow-4: #FFE3B9;
$color-yellow-5: #5A3F0B;


$color-purple-0: #5d20b4;
$color-purple-1: #410e90;
$color-purple-2: #110240;
$color-purple-3: #F3E6FE;
$color-purple-4: #593079;

$color-blue-0: #b6cdff;
$color-blue-1: #adc6ff;
$color-blue-2: #597ef7;
$color-blue-3: #1890ff;
$color-blue-4: #2f54eb;
$color-blue-5: #1d39c4;
$color-blue-6: #083CE8;
$color-blue-7: #2B5BF8;
$color-blue-8: #5C81F9;
$color-blue-9: #0A4999;
$color-blue-10: #D8E1FF;
$color-blue-11: #B3C4FF;

$color-red-0: #fde7ec;
$color-red-1: #f2456a;
$color-red-2: #ef1644;
$color-red-3: #FCE1DF;
$color-red-4: #71312E;
$color-red-5: #DC0F3A;
$color-red-6: rgba(252, 120, 120, 0.2);

$background-menu-color: $color-white-0;
$content-background-color: $color-grey-8;
$menu-box-shadows-color: $color-grey-1;
$content-box-shadows-color: $color-grey-1;

@mixin colorizeStatusTag($background, $color) {
  background-color: $background;
  color: $color;
  & path {
    stroke: $color;
  }
}

